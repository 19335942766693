import React from "react";
import styles from "./ConfigSection.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import ConfigRow from "../ConfigRow/ConfigRow";
import DecimationHeader from "../DecimationHeader/DecimationHeader";

const ConfigSection = ({data, rtuData }) => {

    const parseData = (values) => {
        if(!values) {
            return [];
        }

        const source = values.site;
        const target = values.siteTarget || values.siteDefault;

        const ignoreKeys = ['idrtu_config', 'insert_timestamp', 'rtu', 'id_section', 'id_rtu'];
        let result = [];
        Object.keys(values.siteDefault).forEach(function(key) {
            if(!ignoreKeys.includes(key)) {
                const sourceValue = source && source[key];
                const targetValue = target && target[key];
                const rowName = key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                result.push([false, rowName, sourceValue, targetValue]);   
            }
        });

        return result;
    };

  return (
    <div className="row justify-content-center">
        <div className={`${styles.cardWidth} card mx-3 mb-3`} >
            <div className="card-header">
                <div className="d-flex justify-content-between">
                    <div>
                        <FontAwesomeIcon icon={faSignal} color={rtuData.online ? "green" : "red"} /> 
                        &nbsp; <b>{rtuData.name}</b> &#45; {rtuData.rtu_id}
                    </div>
                </div>
            </div>
            <div>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col"><span className="ml-1">Nombre</span></th>
                            <th scope="col">Esperado</th>
                            <th scope="col">Actual</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data && parseData(data).map((item, index) =>
                            <tr key={index}>
                                <ConfigRow isLabel={item[0]} name={item[1]} source={item[2]} target={item[3]} />                                                   
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                <hr className="mt-2 mb-1"/>
                <DecimationHeader rtu_id={rtuData.rtu_id}/>
            </div>
            <div className="card-footer"><small className="text-muted">Ultima actualizaci&oacute;n: {rtuData.cdt_timestamp}</small></div>
        </div> 
    </div>
  );
};

export default ConfigSection;
