import React, { useEffect, useState, useCallback } from "react";
import AxiosApi from '../../Api';
import styles from "./Config.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import NavBar from "../../UI/NavBar/NavBar";
import ConfigSection from "../../UI/ConfigSection/ConfigSection";

const Config = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { section, rtu } = useParams();
  const [error, setError] = useState(null);
  const [siteConfig, setSiteConfig] = useState(null);
  const [rtuData, setRtuData] = useState(null);

    const refreshConfig = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        const site = section + rtu.padStart(2, '0');
        var requests = [
            AxiosApi.get(`/pipeops/rtu/${site}/site`), 
            AxiosApi.get(`/pipeops/${section}/${rtu}/config`)];
        
        Promise.all(requests).then((result) => {
            const siteResponse = result[0];
            const configResponse = result[1];

            if(siteResponse.status === 200) {
                const siteData = siteResponse.data.data ;
                setRtuData(siteData && siteData.length > 0 ? siteData[0] : {});
            } else {
                setError("Error al cargar la información del RTU");
            }
    
            if(configResponse.status === 200) {
                const configData = configResponse.data.data;
                setSiteConfig(configData);
            } else {
                setError("Error al cargar la configuración");
            }
        }).catch((error) => {
            console.error("Error response:", error);
            setError("Error al cargar la información del RTU");
        }).finally(() => {
            setIsLoading(false);
        });
    }, [section, rtu, setError, setSiteConfig, setRtuData]);

  useEffect(() => {
    refreshConfig();
  }, [refreshConfig]);

  return (
    <>
    <NavBar/>
    <div className="container-fluid">
        {
            isLoading ? ( 
                <div className="row justify-content-center p-2">
                    <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`}/>
                </div>
            ) : (
                error ? (
                    <div className="p-2">
                        <div className="row justify-content-center">
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={refreshConfig} >
                                <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm"/> &nbsp;Actualizar
                            </button>
                        </div>
                    </div>
                ) : (                    
                    <div className="p-2">
                        <div className="row justify-content-center">
                            <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={refreshConfig} >
                                <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm"/> &nbsp;Actualizar
                            </button>
                        </div>
                        <ConfigSection data={siteConfig} rtuData={rtuData}/>
                    </div>
                )
            )
        }
    </div>
    </>
  );
};

export default Config;
