import React from "react";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import NotFound from "./Pages/NotFound/NotFound";
import InternalError from "./Pages/InternalError/InternalError";
import Comm from "./Pages/Comm/Comm";
import Config from "./Pages/Config/Config";

const authGuard = (Component) => () => {
  return sessionStorage.getItem("token") ? (
    <Component />
  ) : (
    <Redirect to="/login" />
  );
};
const Routes = (props) => (
  <Router {...props}>
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/dashboard" render={authGuard(Dashboard)}></Route>
      <Route path="/comm/:id?" render={authGuard(Comm)} ></Route>
      <Route path="/config/:section/:rtu" render={authGuard(Config)} ></Route>
      <Route path="/InternalError" render={authGuard(InternalError)}></Route>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path="*" render={authGuard(NotFound)}></Route>
    </Switch>
  </Router>
);

export default Routes;
