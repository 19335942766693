import React from "react";
import Api from '../../Api';
import { useHistory } from "react-router-dom";
import styles from "./NavBar.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSignOutAlt, faSitemap } from '@fortawesome/free-solid-svg-icons'

const NavBar = () => {
    const history = useHistory();

    const logout = async () => {
        const username = sessionStorage.getItem("username");
        try {
          if(username) {
            await Api.post(`/user/${username}/logout`);
          }
        } catch (err) {
          console.error(err);
        }
        sessionStorage.clear();
        history.push("/login");
    };
    
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <a className="navbar-brand" href="/">
          <img src="/logo.png" className={`${styles.logo}`} alt="Pipe eagle"/>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
        <ul className="navbar-nav mr-auto">
            <li >
              <a className="nav-link" href="/dashboard">
                <FontAwesomeIcon icon={faHome}/>&nbsp;
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li >
              <a className="nav-link" href="/comm">
                <FontAwesomeIcon icon={faSitemap}/>&nbsp;
                Comm Protocol <span className="sr-only">(current)</span>
              </a>
            </li>            
          </ul>          
          <span className="navbar-text">Welcome! {sessionStorage.getItem("name")}
          </span>
          <span className="nav-link cursor-pointer" onClick={() => logout()}>
            <FontAwesomeIcon icon={faSignOutAlt}/>&nbsp;
            Logout
          </span>
        </div>
      </nav>

    );
};

export default NavBar;