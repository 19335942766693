import axios from 'axios';

const AxiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

AxiosApi.interceptors.request.use(config => {
  const token = sessionStorage.getItem("token");
  if(token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  console.log('interceptor:request:error', error);
  return Promise.reject(error);
});

export default AxiosApi;
