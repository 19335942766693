import React from "react";
import Api from '../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default class InternalError extends React.Component {
    logout = async () => {
        const username = sessionStorage.getItem("username");
        if(username) {
            try {
            await Api.post(`/user/${username}/logout`);
            } catch (err) {
            console.error(err);
            }
        }
        sessionStorage.clear();
        window.location.href = '/login'
    };

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <h1>Internal Error</h1>
                </div>
                <div className="row justify-content-center">
                    <span className="nav-link cursor-pointer" onClick={this.logout}>
                        <FontAwesomeIcon icon={faSignOutAlt}/>&nbsp;
                        Cerrar sesi&oacute;n
                    </span>
                </div>                
            </div>
        );
    }
}
