    import React, { useEffect, useState, useCallback } from "react";
    import AxiosApi from '../../Api';
    import styles from "./Dashboard.module.css";
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faSpinner, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
    import NavBar from "../../UI/NavBar/NavBar";
    import RTUData from "../../UI/RTUData/RTUData";

    const Dashboard = () => {
      const [searchValue, setSearchValue] = useState("");
      const [isLoading, setIsLoading] = useState(true);
      const [siteData, setSiteData] = useState([]);
      const [error, setError] = useState(null);
      
      const filterSites = ({ name, mobile_id, rtu_id }) => {
        return (name || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        (mobile_id || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        (rtu_id || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      };

      const loadData = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        
        const requests = [
          AxiosApi.get('/pipeops/decimation'), 
          AxiosApi.get('/pipeops')
        ];

        Promise.all(requests).then((result) => {
          const decimationResponse = result[0];
          const response = result[1];

          if(decimationResponse.status === 200 && response.status === 200) {
            const sites = response.data.data;
            const decimation = decimationResponse.data.data;
            setSiteData({sites, decimation});
          } else {
            setError("Error al cargar la información del RTU");
          }      
        }).catch((error) => { 
          console.error("Error response:", error);
          setError("Error al cargar la información del RTU");
        }).finally(() => {
          setIsLoading(false);
        });

      }, []);

      useEffect(() => {
        loadData();
      }, [loadData]);

      return (
        <>
          <NavBar/>
            {
              isLoading ? (
                <div className="container">
                  <div className="row justify-content-center p-2">                
                    <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`}/>
                  </div>
                </div>
              ) : (
                error ? (
                  <div className="container p-2">
                      <div className="row justify-content-center">
                          <div className="alert alert-danger" role="alert">
                              {error}
                          </div>
                      </div>
                      <div className="row justify-content-center">
                          <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={loadData} >
                              <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm"/> &nbsp;Reintentar
                          </button>
                      </div>
                  </div>
                ) : (
                  siteData && siteData.sites && siteData.sites.length > 0 ? (
                    <div className="container">
                      <div className="row justify-content-center p-2">  
                        <input
                          className="search mb-3 mt-3"
                          type="text"
                          placeholder="Filtrar"
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}/>
                        </div>
                        {
                          siteData.sites.filter(filterSites).map(q =>
                            <RTUData key={q.Key} siteData={q} decimation={siteData.decimation} />
                          )
                        }                      
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="alert alert-warning" role="alert">
                            No se encontraron datos
                        </div>
                      </div>
                      <div className="row justify-content-center">
                          <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={loadData} >
                              <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm"/> &nbsp;Reintentar
                          </button>
                      </div>            
                    </div>
                  )                
                )
              )
            }
        </>
      );
    };

    export default Dashboard;
