import React, { useEffect, useState } from "react";
import AxiosApi from '../../Api';
import AxiosCommApi from '../../CommApi';
import styles from "./Comm.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../../UI/NavBar/NavBar";
import Modal from 'react-modal';
import xml2js from "xml2js";
import { useParams } from "react-router-dom";

const decryptModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '10px',
  }
};

Modal.setAppElement('#root')

const Comm = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [siteData, setSiteData] = useState([]);
  const { id } = useParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isModalLoading, setModalLoading] = React.useState(true);
  const [decryptFrames, setDecryptFrames] =  useState(null);
  const [decryptReport, setDecrypReports] =  useState(null);
  const [decryptError, setDecryptError] = useState(null);

  
  function openModal(message) {
    setDecryptError(null);
    setDecryptFrames(null);
    setDecrypReports(null);
    setModalLoading(true);
    setIsOpen(true);
    AxiosApi.post(`/pipeops/parse`, message )
    .then(res => {
      if(res.data.Error) {
        setDecryptError(res.data.Error);
      } else if(res.data.data._packedFrames) {
        setDecryptFrames(res.data.data._packedFrames)
      } else if(res.data.data.reportFrame) {
        setDecrypReports(res.data.data.reportFrame);
      }

      setModalLoading(false);
    }).catch( error => {  
      setDecryptError("Error al desencriptar el mensaje");
      setModalLoading(false);
    });
  }
 
  function afterOpenModal() {

  }
 
  function closeModal(){
    setIsOpen(false);
  }

  const filterSites = ({ MobileID }) => {
    return (""+MobileID).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };

  useEffect(() => {
    if(id) {
      setSearchValue(id);
    }
    AxiosApi.get(`/pipeops/comm`)
    .then(res => {
      const end = Buffer.from(res.data.data, 'base64').toString();
      AxiosCommApi.get(end).then( resp => {
        let parser = new xml2js.Parser();
        parser.parseString(resp.data,
            function(err, result) {
                if(result && result.GetReturnMessagesResult && result.GetReturnMessagesResult.Messages && result.GetReturnMessagesResult.Messages[0])
                {
                  const data = result.GetReturnMessagesResult.Messages[0].ReturnMessage;
                  for(var i = 0; i  < data.length; i++) {
                    data[i].MobileID[0] = data[i].MobileID[0].replace("SKY","");
                  }

                  setSiteData(data);
                  setIsLoading(false);   
                }
            }
        );
        }).catch( error => {
          console.error("Error response:", error);
          setIsLoading(false);
          setDecryptError("Error al cargar la información del RTU");
        });
    });
  }, [id, setSiteData, setSearchValue, setDecryptError, setIsLoading]);

  return (
    <>
      <NavBar/>
      <div className="container">
        <div className="row justify-content-center p-2">
          <input
            className="search mb-3 mt-3"
            type="text"
            placeholder="Filtrar"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
        {
          isLoading && 
          <div className="row justify-content-center">
          <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`}/>
          </div>
        }
      { 
            siteData && siteData.filter(q => q.RawPayload !== null).filter(filterSites).map(data => 
                <div key={""+data.ID} className="row justify-content-center">
                <div className={`${styles.cardWidth} card mx-3 mb-3`} >
                  <div className="card-header">
                    <FontAwesomeIcon icon={faGlobe}/>
                    &nbsp;
                    <b>{data.MobileID}</b>
                    </div>
                  <div className="card-body">
                    <div className="card-text">
                      <div className="row">
                        <div className="col">
                          ID: {data.ID}
                        </div>                             
                        <div className="col">
                          SIN: {data.SIN}
                        </div>                   
                        <div className="col">
                          OTA Size: {data.OTAMessageSize}
                        </div>                        
                      </div> 

                      <div className="row" >
                        <div className="col">
                          <div className={`${styles.wrapXml}`}>
                            <button type="button" onClick={() => openModal(data)} className={`btn btn-link ${styles.fixedLink}`}>Encrypted:</button>
                            &nbsp; {data.RawPayload}
                          </div>
                        </div>
                      </div>             
                    </div>
                  </div>
                  <div className="card-footer"><small className="text-muted">Timestamp: {data.ReceiveUTC}</small></div>
                </div>
              </div>
        )
        }       
      </div>
      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={decryptModalStyles}
          contentLabel="Desencriptar mensaje"
        >
          {
            isModalLoading &&
            <div className="container">
              <div className="row justify-content-center">
                Procesando el mensaje por favor espere
              </div>
              <div className="row justify-content-center">
                <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`}/>
              </div>
            </div>
          }
          {
            decryptError && 
            <div className="container">
              <div className="row justify-content-center">
                <FontAwesomeIcon icon={faExclamationCircle} color="orange"/>
                &nbsp;
                {decryptError}
              </div>
            </div>            
          }
          {
            decryptFrames && 
            <>
              <div className="d-flex flex-row-reverse">
                <button type="button" className={`${styles.btnClose}`} aria-label="Close" onClick={closeModal}></button>
              </div>
            <div className="mt-2">           
              <div className={`${styles.tableScrollable}`}>
                <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Section</th>
                      <th scope="col">Flow</th>
                      <th scope="col">Timestamp</th>
                      <th scope="col">Nanoseconds</th>
                      <th scope="col">Pressure</th>
                      <th scope="col">Threshold</th>
                      <th scope="col">Leak</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    decryptFrames.map((data, index) =>
                      <tr key={index}>
                        <td>{data._id_rtu}</td>
                        <td>{data._id_section}</td>
                        <td>{data._flow}</td>
                        <td>{data._timestamp}</td>
                        <td>{data._ns_timestamp}</td>
                        <td>{data._pressure}</td>
                        <td>{data._threshold}</td>
                        <td>{data._leak}</td>
                      </tr>
                    )
                  }
                  </tbody>
                  </table>
                </div>
            </div>
          </div>                               
            </>
          }
          {
            decryptReport && 
            <>
              <div className="d-flex flex-row-reverse">
                <button type="button" className={`${styles.btnClose}`} aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="mt-2">
              <div className={`${styles.tableScrollable}`}>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Section</th>                  
                        <th scope="col">Timestamp</th>
                        <th scope="col">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      decryptReport._data.map((data, index) =>
                        <tr key={index}>
                          <td>{decryptReport._id_rtu}</td>
                          <td>{decryptReport._id_section}</td>
                          <td>{data.timestamp}</td>
                          <td>{data.value}</td>
                        </tr>
                      )
                    }
                    </tbody>
                  </table>
                </div>
              </div>
          </div>                            
            </>
          }          
        </Modal>  
    </>
  );
};

export default Comm;
