import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faLocationArrow, faPowerOff, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import DecimationHeader from "../../UI/DecimationHeader/DecimationHeader";
import styles from "./RTUData.module.css";

const RTUData = ({ siteData, decimation }) => {

    return (
        <div className="row justify-content-center">
            <div className={`${styles.cardWidth} card mx-3 mb-3 ${siteData.online ? "" : "border-danger"}`} >
                <div className="card-header">
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faSignal} color={siteData.online ? "green" : "red"} /> 
                                &nbsp; <b>{siteData.name}</b> &#45; {siteData.rtu_id} 
                        </div>
                        <div>
                            <Link to={{ pathname: `/config/${siteData.section}/${siteData.site}` }}>
                                <FontAwesomeIcon icon={faExternalLinkSquareAlt} /> 
                            </Link>                            
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="card-text">
                    <div className="row">
                        <div className="col">
                        <Link to={{
                            pathname: '/comm/'+siteData.mobile_id
                        }}>{siteData.mobile_id}</Link>
                        </div>
                        <div className="col">
                        KM: {siteData.km}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                        Pressure: {siteData.pressure}
                        </div>
                        <div className="col">
                        Lat: {siteData.lat}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                        Threshold: {siteData.threshold}
                        </div>
                        <div className="col">
                        Long: {siteData.lon}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                        Report: {siteData.report_data}  
                        </div>
                        <div className="col">
                        <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com?q=${siteData.lat},${siteData.lon}`}>
                            <FontAwesomeIcon icon={faLocationArrow} size="sm"/>
                            &nbsp;Mapa
                        </a>
                        </div>
                    </div>  
                        <div className="row">
                        <div className="col">
                            RTU version: {siteData.last_message ? siteData.last_message.replace("Start ", "V.") : ""}
                        </div>
                        <div className="col">
                            <FontAwesomeIcon icon={faPowerOff} size="sm" color={siteData.enabled ? "green" : "red"} />
                            &nbsp; {siteData.enabled ? "Habilitado" : "Deshabilitado"}
                        </div>
                        </div>
                        <hr className="mt-2 mb-1"/>
                        <DecimationHeader initialDecimation={decimation.filter(r => r.rtu_id === siteData.rtu_id)} rtu_id={siteData.rtu_id}/>
                    </div>
                </div>
                <div className="card-footer"><small className="text-muted">Ultima actualizaci&oacute;n: {siteData.cdt_timestamp}</small></div>
            </div>
        </div> 
    );
};

export default RTUData;
