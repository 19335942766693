import React, { useState, useEffect, useCallback } from "react";
import AxiosApi from '../../Api';
import styles from "./DecimationHeader.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import DecimationField from "../DecimationField/DecimationField";

const DecimationHeader = ({initialDecimation, rtu_id}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [decimation, setDecimation] = useState(initialDecimation);

    const  refreshDecimation = useCallback(async () => {
        setIsLoading(true);
        const result = await AxiosApi.get(`/pipeops/decimation/${rtu_id}/site`);

        if(result.data.code === 200) {
            setDecimation(result.data.data);
        } else {
            alert.show("Connection error")
        }
        setIsLoading(false);

    }, [rtu_id]);

    // Load data when the component mounts
    useEffect(() => {
        refreshDecimation();
    }, [refreshDecimation]); // Empty array means this effect runs once on mount


    return (
    <div className={!initialDecimation ? "p-2" : ""}>
        <div className="row">
            <div className="col">                          
                <p className="card-text"><small className="text-muted">Decimaci&oacute;n</small></p>
            </div>
            <div className="col">
                <small>
                {
                isLoading === true ? (
                    <span className="btn btn-sm">
                    <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${styles.spinSize}`}/> &nbsp;Cargando
                    </span>                                      
                ) : (
                    <button className="btn btn-link btn-sm" title="Actualizar" type="button" onClick={refreshDecimation} >
                    <FontAwesomeIcon icon={faCloudDownloadAlt} size="sm"/> &nbsp;Actualizar
                    </button>
                )
                }
                </small>
            </div>
        </div>
        {
            decimation && decimation.length > 0 ? (
            <>
                <DecimationField reportDate={decimation[0].report_date1} value={decimation[0].data1} isLoading={isLoading}/>
                <DecimationField reportDate={decimation[0].report_date2} value={decimation[0].data2} isLoading={isLoading}/>
                <DecimationField reportDate={decimation[0].report_date3} value={decimation[0].data3} isLoading={isLoading}/>
            </>                
            ) : ("")
        }    
    </div>    
    );
};

export default DecimationHeader;