import React from "react";

const DecimationField = ({reportDate, value, isLoading}) => {

    return (
    reportDate && reportDate !== "" && 
        <div className='row'>
            <div className={`col ${isLoading ? 'text-muted': ''}`}>{reportDate}</div>
            <div className={`col ${isLoading ? 'text-muted': ''}`}>{value}</div>
        </div>    
    );
};

export default DecimationField;