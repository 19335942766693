import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ConfigRow = ({ name, source, target }) => {

    const source_target = source === target;
    return (
    <>
        <th scope="row">
            <span className="ml-1">{name}</span>
        </th>
        <td>{target}</td>
        <td>{source}</td>
        <td>
            <FontAwesomeIcon icon={source_target ? faCheckCircle : faTimesCircle} color={source_target ? "green" : "red"} />
        </td>
    </>
    );
};

export default ConfigRow;
